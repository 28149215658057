@import 'global';
@import 'variables';
@import 'functions';

.mt-1em {
  margin-top: 1em !important;
}

.no-margin {
  margin: 0 !important;
}

.mb-1em {
  margin-bottom: 1em !important;
}

.width-50 {
  width: 50%;
}

.center-children {
  display: flex;
  justify-content: center;
  align-content: center;
}

.display-flex {
  display: flex;
}

.bold-font {
  font-family: $text-font-regular;
  font-weight: bold;
}

/* App logo */
.app_logo_default {
  // width: 8rem;
  height: 2.8rem;
  cursor: pointer;
}

.toolbar {
  min-height: 4.25rem;
}

/* popover styles */

// START - Style for Dropdown box shadow
.MuiMenu-paper {
  box-shadow: 0 0.3125rem 0.3125rem -0.1875rem rgba(0, 0, 0, 0.2),
    0 0.5rem 0.625rem 0.0625rem rgba(0, 0, 0, 0.14),
    0 0.1875rem 0.875rem 0.125rem rgba(0, 0, 0, 0.12) !important;
  border-radius: 0;
}

// END

.text-uppercase {
  text-transform: uppercase;
}

.cancel-button-link {
  color: $font-tertiary-color !important;
  text-decoration: underline !important;
  text-transform: capitalize !important;
}

/*
* Form css
*/

.Mui-error {
  .MuiSelect-root {
    border-color: $app-error-color;
  }

  .MuiSelect-icon {
    color: $app-error-color;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

/*
 Green background button
 */
.add-button-container {
  background-color: $app-secondary-color !important;
}

/*
 Build tag - will be removed in future
 */
.build-version-number {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 8px;
  border-top-left-radius: 8px;
  background-color: lightgray;
  color: white;
}

.secondary-btn {
  color: $default-white-color !important;
  background: $default-white-color !important;
}

.form-field-loader-inside {
  width: 100%;
  display: flex;
  flex-direction: row;

  .loader {
    position: absolute;
    right: 10px;
    margin-top: 15px;
  }
}

.nav-page-padding {
  // padding: 4rem 3rem;
  padding: 0 !important;
}

.page-header-padding {
  padding: 4rem 3rem !important;
  padding-bottom: 2rem !important;
}

.page-content-padding {
  padding: 0rem 3rem !important;
  padding-bottom: 4rem !important;
}

.page-content-left-right-padding {
  padding: 0rem 3rem !important;
}

.page-content-top-bottom-padding {
  padding: 4rem 0rem !important;
}

.page-padding {
  padding: 4rem 3rem !important;
}

.nav-top-page-padding-reset {
  padding-top: 0 !important;
}

.sub-tab-routes-padding {
  .page-header-padding {
    padding: 0rem 3rem !important;
    padding-bottom: 2rem !important;
  }

  .page-content-padding {
    padding: 1rem 0rem !important;
    padding-bottom: 4rem !important;
  }

  .page-content-top-bottom-padding {
    padding: 2rem 0rem !important;
  }
}

.back-item {
  font-size: 0.75rem;
  color: $font-tertiary-color;
  letter-spacing: 0.06rem;
  text-transform: uppercase;

  .MuiButton-startIcon {
    margin: 0;
    color: $border-primary-color;

    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }
}

/*  styles for select placeholder */
.selectPlaceholder {
  div[class*='MuiSelect-selectMenu'] {
    color: grey !important;
  }
}

/*
  GLOBAL STYLES FOR DIALOG COMPONENTS - START
*/
.cursor-auto {
  cursor: auto !important;
}

.sub-menu-item {
  padding-left: 3rem !important;
}

.landing-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .title {
    font-size: 1.4rem;
    color: $default-black-color;
    opacity: 1;
    margin-left: 1rem;
  }
  .child-wrapper {
    display: flex;
    align-items: center;
  }
}

.default-img {
  background: $default-logo-color;
}

.MuiPopover-paper {
  border-radius: get_8_pixel_box(1) !important;
  margin-top: 1rem;
  background: $default-white-color 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  opacity: 1;
}

.MuiMenuItem-root {
  min-height: 3rem !important;
  font-size: 1.2rem !important;
  text-transform: capitalize;
}

.MuiFormControl-root {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.MuiListItem-button:hover {
  background-color: $list-hover-color !important;
}

.Mui-selected:hover {
  background-color: $list-hover-color !important;
}
.MuiInputBase-multiline {
  font-size: 1.3rem !important;
}

fieldset {
  border: none !important;
}
.divider-container {
  border: 1px dashed #e6e6e6;
}
.appointment-section {
  padding: 2rem 2rem 1rem 3rem;
  .today-status {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    .bubble-view {
      width: 10px;
      height: 10px;
      opacity: 1;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
    .available-color {
      background: $green-color-1;
    }
    .not-available-color {
      background: $grey-color-3;
    }
    .available-text {
      color: $green-color-1;
      font-size: 1.15rem;
    }
    .not-available-text {
      color: $grey-color-3;
      font-size: 1.15rem;
    }
  }
}

em {
  font-style: normal;
  font: normal normal normal 1.2rem/1.5rem $text-font-regular;
  color: $font-primary-color;
}

button {
  box-shadow: none !important;
  text-transform: capitalize !important;
}

button::first-letter {
  text-transform: capitalize !important;
}

.btn-section {
  background: $app-primary-color 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  button {
    // padding: 1rem 1.5rem !important;
    font-size: 1.4rem !important;
    text-transform: capitalize;
  }
}

.yellow-bg-2 {
  background: $yellow-2;
}

.blue-bg-2 {
  background: $blue-2;
}

.blue-bg-4 {
  background: $blue-4;
}

.booking-appointment-popup {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: get_8_pixel_box(2.625) !important;
  opacity: 1 !important;
  max-width: 49rem !important;
  max-height: calc(100% - 0.5rem) !important;

  .popup-left-section {
    background: $app-primary-color 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .booking-appointment-popup-title {
    background: $app-primary-color 0% 0% no-repeat padding-box;
    padding: 1.5rem 2rem;
  }

  .user-post-popup-title {
    padding: 2rem 0 0 3rem;
  }

  .booking-appointment-popup-content {
    padding: 1.5rem 2rem;
    width: 95%;
    margin: 0 auto;

    .information-text {
      font: normal normal normal 1.1rem/1.5rem $text-font-regular;
    }

    .helper-text {
      font: normal normal normal 0.9rem/1.5rem $text-font-regular;
    }

    .action-button-container {
      background: $background-other-color 0% 0% no-repeat padding-box;
      border: 1px solid $border-other-color;
      border-radius: get_8_pixel_box(0.375);
      font: normal normal 600 1rem/1.8rem $text-font-regular;
      color: $app-primary-color;
    }
  }

  .booking-appointment-popup-actions {
    padding: 1.5rem 2rem;
    width: 95%;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: flex-start;

    .normal-text {
      font: normal normal normal 0.9rem/1.2rem $text-font-regular;
    }

    .skip-link {
      font: normal normal normal 1.1rem/1.5rem $text-font-regular;
      color: $font-primary-color;
      margin-top: 1rem;
    }
  }
}

.person-doctor-popup {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: get_8_pixel_box(2.625) !important;
  opacity: 1 !important;
  max-width: 70rem !important;
  max-height: calc(100% - 0.5rem) !important;

  .popup-left-section {
    background: $app-primary-color 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .popup-right-section {
    padding: 1rem 2rem !important;
    padding-bottom: 1rem !important;
  }

  .person-doctor-popup-content {
    .password-info-text {
      font: normal normal normal 0.8rem/1.1rem $text-font-regular;
      color: $grey-color-3;
    }

    .select-user-text {
      font: normal normal 600 1.1rem/1.5rem $text-font-regular;
    }
  }

  .person-doctor-popup-actions {
    margin: 0 1rem;

    .permissions-text {
      padding-left: 1rem;
    }

    .account-existence-text {
      padding-left: 1rem;
      font: normal normal 600 1rem/1.5rem $text-font-regular;
    }

    .tos-link-text {
      text-decoration: underline;
      color: $font-primary-color;
      text-transform: none !important;
    }
  }
}

.register-close-icon {
  position: absolute;
  right: 0;
}

.landscape-orientation-popup {
  max-width: 80rem !important;
}

.temporary-user-registration-popup {
  max-width: 62rem !important;
}

.confirmation-box-popup {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: get_8_pixel_box(2.625) !important;
  opacity: 1 !important;
  max-width: 40rem !important;

  .popup-right-section {
    padding: 2rem !important;

    .popup-content {
      padding-top: 0;
    }
  }
}

.link-text {
  font: normal normal bold 1rem/1.2rem $text-font-regular !important;
  color: $app-primary-color;
}

.submit-button {
  width: 100%;
  font: normal normal 600 1.2rem/1.5rem $text-font-regular !important;
  height: 3.5rem;
  border-radius: get_8_pixel_box(0.5);
}

// international-tel-input css
.flag-dropdown {
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  margin-left: 0.5rem !important;
}

.flag-dropdown.open {
  width: 100%;
}

.react-tel-input {
  border: 1px solid $border-primary-color !important;
  border-radius: 4px !important;

  .flag-dropdown.open {
    margin-top: 6.5rem;
  }
}

.react-tel-input .country-list .country.highlight {
  background-color: $app-primary-color;
}

.react-tel-input:focus {
  border: none !important;
}

.phone-wrapper-container {
  .phone-input-container {
    width: 100% !important;
    border: none !important;
    color: $default-black-color !important;
    font-family: $text-font-regular;
    font-size: 1rem !important;
    height: 3.25rem !important;
  }

  .custom-phone-input-container {
    height: 2.5rem !important;
  }

  .phone-drop-down-container {
    width: 100% !important;
    text-align: left !important;
    margin-top: -2rem !important;
    background-color: $default-white-color !important;
    opacity: 1 !important;
    transform: none !important;
    box-shadow: 0 0.3125rem 0.3125rem -0.1875rem rgba(0, 0, 0, 0.2),
      0 0.5rem 0.625rem 0.0625rem rgba(0, 0, 0, 0.14),
      0 0.1875rem 0.875rem 0.125rem rgba(0, 0, 0, 0.12) !important;
  }
}

input[type='search' i]::-webkit-search-cancel-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  text-align: left;
  font: normal normal normal 1.2rem/1.4rem $text-font-regular;
  letter-spacing: 0;
  color: $grey-color-3 !important;
  opacity: 1 !important;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input::-webkit-input-placeholder {
  font: normal normal normal 1.2rem/1.4rem $text-font-regular;
  color: $font-primary-color;
  opacity: 1;
}

.css-1ikexvl-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: $app-primary-color !important;
  color: $default-white-color;
}

.done-icon {
  color: $border-tertiary-color;
}

.success-icon {
  color: $app-success-color;
}

.form-combination-field-textfield-input {
  .MuiOutlinedInput-input {
    padding-left: 0 !important;
  }
}

.whitelabel-name-big-font {
  font-size: 1.7rem !important;
}

.custom-snackbar-class {
  margin-top: 50%;
}

.normal-bmi-color {
  .MuiOutlinedInput-input {
    color: $normal-bmi-color;
  }
}

.ab-normal-bmi-color {
  .MuiOutlinedInput-input {
    color: $ab-normal-bmi-color;
  }
}

.medium-bmi-color {
  .MuiOutlinedInput-input {
    color: $medium-bmi-color;
  }
}

.custom-text-filed-height {
  .MuiOutlinedInput-input {
    height: 0.2rem;
  }
}

/*
  GLOBAL STYLES FOR DIALOG COMPONENTS - END
*/
