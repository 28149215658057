$app-side-bg: #e6ecf0;
$app-primary-color: #04a6d6;
$app-secondary-color: #3839aa;

$text-primary-font-size: 18px;
$text-secondary-font-size: 16px;
$text-tertiary-font-size: 12px;
$text-font-size: 14px;
$text-tertiary-font-size_1: 14px;
$text-tertiary-font-size_2: 20px;
$text-tertiary-font-size_3: 15px;
$text-tertiary-font-size_4: 13px;
$text-tertiary-font-size_5: 22px;
$text-tertiary-font-size_6: 17px;

$app-primary-font-weight: 1000;
$app-secondary-font-weight: 700;
$app-tertiary-font-weight: 400;
$app-font-weight: 500;

$app-tertiary-font-weight_1: 600;
$app-tertiary-font-weight-2: 500;
$app-tertiary-font-weight-3: 300;
$app_primary_font_regular: 'Nunito Sans';
$app_primary_font_regular_1: 'Nunito';
$app_secondary_font_regular: 'PT Serif';

$app-error-color: red;
$app-success-color: green;

$text-error-color: $app-error-color;
$text-success-color: $app-success-color;

$text-font-regular: $app_primary_font_regular;

$font-primary-color: #1d1d24;
$font-secondary-color: #151414;
$font-tertiary-color: #151e24;
$font-other-color: #808080;
$date-color: #111111;
$or-text-color: #777777;
$font-tertiary-color-1: #464646;
$font-tertiary-color-2: rgba(55, 99, 112, 0.62);

$background-primary-color: #e0e0e0;
$background-color-8: #f8f5f5;
$background-color-9: #fffdfd;
$background-color-10: #b1b1b1;
$background-color-11: #a5a5a5;
$background-secondary-color: #252dbc;
$background-tertiary-color: #eff2f4;
$background-other-color: #eaf3f5;
$background-color1: #e8f0f2;
$background-color2: #fafeff;
$background-color3: #7ecae6;
$background-color4: #f6f6f6;
$background-color5: #e0e1e0;
$background-color6: #e3ffee;
$background-color7: #d9ebf0;
$background-color-menuStructure: #e8e8e8;
$background-color8: #e8f5f9;
$border-primary-color: #d5d9e3;
$border-secondary-color: #bbe2ca;
$border-tertiary-color: #d3d3d3;
$border-other-color: #93c7d6;
$border-right-color: #c5c5c5;
$drop-background-color: #e8f0fe;
$verified-border-color: #ff7474;
$verified-background-color: #ffe5e5;
$paper-background-color-op: rgba(255, 255, 255, 0.19);
$pop-background-color: #eeeeee;
$patient-dashboard-background-color: #0d5970;
$patient-dashboard-related-color: #d4eef4;
$footer-font-color: #93e6ff;
$white-background-color: #fbfbfb;
$white-background-color1: #eff7f9;
$white-background-color-1: #fdfdfd;
$default-white-color: #ffffff;
$default-black-color: #000000;
$card-background-color: #f2f2f2;
$shadow-color: #0000001a;

$placeholder-text-color: #9d9d9d;
$placeholder-text-color1: rgb(197, 190, 190);
$light-green-color: #62d27c;
$light-grey-color: #ececec;
$grey-color-1: #8f8f90;
$grey-color-2: #eeeff2;
$green-color: #0a9841;
$green-color-1: #0fb951;
$green-color-2: #1e917f;
$light-blue-color: #e8f6fa;
$light-blue-color-1: #edfbff;
$white-color-1: #e6e6e6;
$default-logo-color: #f7f7f7;
$grey-color-3: #646467;
$grey-color-4: #f4f4f4;
$grey-color-5: #f8f8f8;
$grey-color-6: #e8f0f2;
$yellow-1: #fff9f4;
$yellow-2: #faebdb;
$blue-1: #e7eef0;
$blue-2: #e6e2ff;
$blue-3: #275e81;
$blue-5: #138fb4;
$blue-4: #c9f0ff;
$orange-1: #e46700;
$light-text-color: #05a6d7;
$description-text-color: #f67f7d;

$hospital-title-color: $font-primary-color;
$hospital-view-border-color: #e6e6e6;
$hospital-view-title-color: #646467;
$hospital-view-op-title-color: #f4f4ff;
$doctor-view-border-color: #e6e6e6;
$doctor-title-color: $font-primary-color;
$doctor-view-title-color: #646467;
$hospital-view-background-color: #fafafa;
$background-order-color: #dbe4e6;
$focused-card-background: #cbf3ff;
$sub-tabs-selected-link-color: #04a6d6;
$button-primary-color: #def7e7;
$doctor-dashboard-divider: rgb(37, 37, 37);
$doctor-dashboard-arrow-background: rgba(21, 20, 20, 0.28);
$doctor-new-patient-background: #ffce00;

$feedback-username-color: #1d1d24;
$feedback-days-color: #646467;
$feedback-border-color: #e6e6e6;
$feedback-star-selected-color: #0fb951;
$feedback-star-unselected-color: #e6e6e6;

$list-hover-color: #d9f0f7;
$button-border-color: #e0bdbb;
$button-background-color: #ffeeed;

$box-shadow-color: #00000029;
$box-shadow-color1: #00000033;
$box-shadow-color2: #00000024;
$box-shadow-color3: #0000001f;
$box-shadow-color4: rgba(166, 204, 215, 0.57);
$box-shadow-color5: rgba(255, 0, 0, 0.15);
$box-shadow-color6: rgba(0, 255, 0, 0.15);
$box-shadow-color7: #dde8ec;

$footer-section1: #355e7f;
$footer-section2: #2a5272;
$footer-section3: #223645;

$public_top_nav_bar_border: #cdcad0;
$video-popup-bg-color: #474747;
$video-status-color: #09b75a;
$video-doctor-patient-avatar-bg-color: #e64c4c;
$video-button-bg-color: #274d59;

//consultation variables
$dashboard-background-color: #f3f3f3;
$border-color-box: #c4c4c4;
$border-color-box-2: #dbe4e6;
$prescription-color: #ff6060;
$prescription-background-color: #ffeded;
$patient-card-background-color: #ebfbff;

$calendar-not-selected-color: rgba(125, 165, 176, 0.19);
$other-message-border-color: #b7b7b7;

$timeline-report-border-color: #d0d0d0;
$timeline-report-border-color_1: #ccc;
$timeline-report-status-color: #ffb800;
$timeline-report-status-color_1: #dcf7ff;
$timeline-button-background-color: #efefef;
$timeline-box-shadow-color: rgba(165, 155, 155, 0.25);
$timeline-box-shadow-color_1: #c0c0c0;
$timeline-box-shadow-color_2: #c5c4c5;
$timeline-box-shadow-color_3: rgba(0, 0, 0, 0.2);
$timeline-box-shadow-color_4: rgba(0, 0, 0, 0.19);
$timeline-status-background-color: #ffce0021;
$timeline-date-time-background-color: #c1dce440;
$timeline-box-shadow-color_1: #c0c0c0;
$timeline-box-shadow-color_2: #c5c4c5;
$event-upload-button: #effbff;
$history-box-shadow: rgba(165, 155, 155, 0.25);
$history-button-border-color: #bbbbbb;
$history-online-status: #e3ffee;
$history-box-shadow-color: #ebeef0;

$add-subjective-title-background-color: #f5f5f5;
$medicine-button-border-color: #dbdbdb;
$slot-timing-border-color: #bfbfbf;
$right_border_color: #e3e3e3;

$immunization_background_color: #daf1f8;

$template-selected-background-color: #f9f9f9;

$virtual-clinic-text: #1c2033;
$virtual-clinic-subtitle-text: #5e6581;
$virtual-clinic-bg-color: #eef7fa;
$virtual-clinic-doctor-patient-title-color: #232f33;
$virtual-clinic-doctor-patient-bg-color: #f0f6f8;
$vc-cancel-button-bg-color: #f1f1f1;
$slot-button-border-color: #e0e0e0;
$vc-cancel-info-text-color: #999999;
$normal-bmi-color: $app-success-color;
$ab-normal-bmi-color: $app-error-color;
$medium-bmi-color: #ffbf00;

$subjective-slider-thumb: #c7bfbf;
$subjective-slider-rail: #eceff0;

$vitals-display-box-shadow: #a59b9b40;
$deleted-medicine-background-color: #ffefef;
$deleted-border-strike-through: #ff4b4d;
$outline-border-color: #b6b7b6;
$billing-bg-color: #f3f3f3;
$button-background-color-2: #dddddd;
$button-light-color-1: #cbf3ff;
$payment-option-1: #f8feff;

$scheduler-title-color: #e7f9ff;

$capture-vitals-border-color: #dfdfdf;
$capture-vitals-background-color: #ebfbff;
$capture-vitals-info-border-color: #d8d8d8;
$capture-vitals-info-background-color: #f6f5f5;

$card-sub-header-text: #5d5d5d;
$self-service-sub-title-color: #626262;
$capture-vitals-array-label: #d9d9d9;
$capture-vitals-visit-id-border: #adadad;
$capture-vitals-visit-id-background: #edeaea;
$capture-vitals-border-values-vitals: #cdcdcd;

$medicine-reminder-background: #ecfaf1;
$medicine-reminder-amber-background: #ffebb0;

$person-web-timeline-tabs-background: #c8e3ec;
$person-web-timeline-patient-card-divider: #afafaf;
